export const TIMELINE = [
  {
    year: "2007",
    header: "Založení Planety Her",
  },
  {
    year: "2011",
    header: "Založení REXher",
  },
  {
    year: "2013",
    header: "Počátek spolupráce na propagaci s igloonetem",
    linkLabel: "Případová studie",
    odkaz: "//igloonet.cz/blog/pripadova-studie-planeta-her/",
    title: "Odkaz na případovou studii",
  },
  {
    year: "2014",
    header: "Ve správě máme všechny online kanály",
    text: "a přidáváme další",
  },
  {
    year: "2016",
    linkLabel: "Nový e-shop od igloonetu",
    odkaz: "//planetaher.cz/",
    title: "web planetaher.cz",
    text: "včetně hostingu",
  },
  {
    year: "2017",
    header: "Nasazení measurement protokolu a start pravidelného e-mailingu",
    text: "a přesun do větší prodejny",
  },
  {
    year: "2020",
    header: "První akce Zajíc v pytli v ČR",
    text: "Měření marží, nový věrnostní program",
  },
  {
    year: "2021",
    header: "Pomoc s obsahem a práce na redesignu webu",
  },
]
